import { login, getToken } from './authService';
import { getPublicIP } from './ipService';

export async function fetchRegistrationByDocument(documento) {
  const token = getToken();
  const response = await fetch(`https://apiumadmec.elshadaysystems.com.br/api/inscricoes/documento/${documento}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error('Inscrição não encontrada');
  }
}

export async function createRegistration(cpf, celular, email, id, paymentMethod, installments, cardHolderName, cardNumber, cardBrand, expiryDate, cvv) {
  try {
    const token = getToken();
    const userIp = await getPublicIP(); // Obtém o IP público do usuário
    const response = await fetch('https://apiumadmec.elshadaysystems.com.br/api/inscricoes', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        cpf: cpf,
        celular: celular,
        email: email,
        id: id,
        ip: userIp,
        pagamento:
        {
          metodo: paymentMethod,
          parcelas: installments,
          titularCartao: cardHolderName,
          numeroCartao: cardNumber,
          bandeiraCartao: cardBrand,
          validadeCartao: expiryDate,
          cvvCartao: cvv
        }
      })
    });

    const data = await response.json();
    if (response.ok) {
      return { success: data.success, codigo: data.codigo, cielo: data.cielo, message: data.message || "Inscrição realizada com sucesso!" };
    } else {
      return { success: data.success, message: data.message || "Erro ao realizar inscrição" };
    }
  } catch (error) {
    console.error("Erro ao tentar criar inscrição:", error);
    return { success: false, message: "Erro ao realizar inscrição" };
  }
}

export async function verificarPagamentoAPI(lPaymentID) {
  const token = getToken();
  const paymentid = lPaymentID;

  const response = await fetch(`https://apiumadmec.elshadaysystems.com.br/api/inscricoes/consultarpagamento/${paymentid}`, {
    headers: {
      //'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
  const data = await response.json();

  return data;
};

